import { render, staticRenderFns } from "./learn-path.vue?vue&type=template&id=55bcaead&scoped=true"
import script from "./learn-path.vue?vue&type=script&lang=js"
export * from "./learn-path.vue?vue&type=script&lang=js"
import style0 from "./learn-path.vue?vue&type=style&index=0&id=55bcaead&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55bcaead",
  null
  
)

export default component.exports